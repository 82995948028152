


















import { OptionModel } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import { Component, Vue } from "vue-property-decorator";

export enum RECEIPT_TYPE {
  APPLIED = "Applied",
  UNINDENTIFIED = "Unidentified",
}

const ReceiptTypeSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class ReceiptTypeSelect extends ReceiptTypeSelectProps {
  receiptTypeOptions = [] as OptionModel[];

  created(): void {
    this.getReceiptType();
  }

  handleInput(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  getReceiptType(): void {
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "INVOICE_RECEIPT_TYPE",
    };
    masterServices.listMaster(param).then((res) => {
      this.receiptTypeOptions = res.map((r) => {
        return {
          label: r.value,
          value: r.value,
        };
      });
    });
  }
}
