var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        "allow-clear": true,
        value: _vm.value,
        disabled: _vm.disabled,
        placeholder: _vm.$t("lbl_choose")
      },
      on: { change: _vm.handleChange, input: _vm.handleInput }
    },
    _vm._l(_vm.receiptTypeOptions, function(item, index) {
      return _c(
        "a-select-option",
        { key: index, attrs: { value: item.value } },
        [_vm._v(" " + _vm._s(item.label || "-") + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }